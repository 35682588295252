'use client'
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Image from 'next/image'
import Link from 'next/link'
import { useMemo, useRef, useState } from 'react'

export default function Banner({ props }: any) {
  const divRef = useRef<HTMLDivElement>(null)
  const [indexCurrent, setIndexCurrent] = useState<number>(0)

  const qtd = useMemo(() => {
    let qtd = -1
    props.data.map((item: any) => {
      if (item.attributes.thumbnail.data.attributes.url) {
        qtd = qtd + 1
      }
    })
    return qtd
  }, [props.data])

  function handleNavigate(direction: 'next' | 'previous') {
    let content = document.getElementById('carousel')
    let div = divRef.current?.children[indexCurrent] as HTMLElement
    let currentWidth = div.offsetWidth

    if (direction == 'next') {
      if (indexCurrent == qtd) {
        content?.scroll(0, 0)
        setIndexCurrent(0)
      } else {
        content?.scroll(currentWidth * (indexCurrent + 1), 0)
        setIndexCurrent(indexCurrent + 1)
      }
    }

    if (direction == 'previous') {
      if (indexCurrent == 0) {
        content?.scroll(currentWidth * qtd, 0)
        setIndexCurrent(qtd)
      } else {
        content?.scroll(currentWidth * (indexCurrent - 1), 0)
        setIndexCurrent(indexCurrent - 1)
      }
    }
  }

  return (
    <>
      <div
        ref={divRef}
        className="carousel hidden w-full md:flex"
        id="carousel"
      >
        {props.data &&
          props.data.map((item: any, index: number) => (
            <div
              className="carousel-item w-full"
              key={index}
              id={`item${index}`}
            >
              {item.attributes.link_status ? (
                <Link
                  href={item.attributes.link}
                  target="new"
                  className="contents"
                >
                  <Image
                    src={
                      item.attributes.thumbnail.data.attributes.url
                        ? `${item.attributes.thumbnail.data.attributes.url}`
                        : '/assets/img/default_ban.png'
                    }
                    loading="lazy"
                    alt={item.attributes.title}
                    className="h-100 w-full object-cover"
                    width={1000}
                    height={430}
                  />
                </Link>
              ) : (
                <Image
                  src={
                    item.attributes.thumbnail.data.attributes.url
                      ? `${item.attributes.thumbnail.data.attributes.url}`
                      : '/assets/img/default_ban.png'
                  }
                  loading="lazy"
                  alt={item.attributes.title}
                  className="h-100 w-full object-cover"
                  width={1000}
                  height={430}
                />
              )}
            </div>
          ))}
      </div>
      <div className="">
        <button
          className="absolute left-2 top-2/4 w-12 rounded-none bg-gray-300 p-8 px-1 py-4 opacity-50 hover:!bg-gray-400"
          onClick={() => handleNavigate('previous')}
          aria-label="prev banner"
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>
        <button
          className="absolute right-2 top-2/4 z-90 w-12 rounded-none bg-gray-300 p-8 px-1 py-4 opacity-50 hover:!bg-gray-400"
          onClick={() => handleNavigate('next')}
          aria-label="next banner"
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
      </div>
    </>
  )
}
